import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "efootball"
      //component: () => import("./views/Home.vue")
    },
    {
      path: "/efootball",
      component: () => import("./views/Layout.vue"),
      children: [
        {
          path: "",
          name: "efootball_home",
          component: () => import("./views/fifa/Home.vue")
        },
        {
          path: "tours",
          name: "efootball_tours",
          component: () => import("./views/fifa/ToursList.vue")
        },
        {
          path: "tour/:id",
          name: "efootball_tour",
          component: () => import("./views/fifa/Tour.vue")
        },
        {
          path: "rules",
          name: "efootball_rules",
          component: () => import("./views/fifa/Rules.vue")
        }
      ]
    },
    {
      path: "/ehockey",
      component: () => import("./views/Layout.vue"),
      children: [
        {
          path: "",
          name: "ehockey_home",
          component: () => import("./views/nhl/Home.vue")
        },
        {
          path: "tours",
          name: "ehockey_tours",
          component: () => import("./views/nhl/ToursList.vue")
        },
        {
          path: "tour/:id",
          name: "ehockey_tour",
          component: () => import("./views/nhl/Tour.vue")
        },
        {
          path: "rules",
          name: "ehockey_rules",
          component: () => import("./views/nhl/Rules.vue")
        }
      ]
    },
    {
      path: "/ebasketball",
      component: () => import("./views/Layout.vue"),
      children: [
        {
          path: "",
          name: "ebasketball_home",
          component: () => import("./views/nba/Home.vue")
        },
        {
          path: "tours",
          name: "ebasketball_tours",
          component: () => import("./views/nba/ToursList.vue")
        },
        {
          path: "tour/:id",
          name: "ebasketball_tour",
          component: () => import("./views/nba/Tour.vue")
        },
        {
          path: "rules",
          name: "ebasketball_rules",
          component: () => import("./views/nba/Rules.vue")
        }
      ]
    },
    // {
    //   path: "/rocket-league",
    //   component: () => import("./views/Layout.vue"),
    //   children: [
    //     {
    //       path: "",
    //       name: "rocket_league_home",
    //       component: () => import("./views/rl/Home.vue")
    //     },
    //     {
    //       path: "tours",
    //       name: "rocket_league_tours",
    //       component: () => import("./views/rl/ToursList.vue")
    //     },
    //     {
    //       path: "tour/:id",
    //       name: "rocket_league_tour",
    //       component: () => import("./views/rl/Tour.vue")
    //     },
    //     {
    //       path: "rules",
    //       name: "rocket_league_rules",
    //       component: () => import("./views/rl/Rules.vue")
    //     }
    //   ]
    // },
    {
      path: "/counter-strike",
      component: () => import("./views/Layout.vue"),
      children: [
        {
          path: "",
          name: "counter_strike_home",
          component: () => import("./views/cs/Home.vue")
        },
        {
          path: "tours",
          name: "counter_strike_tours",
          component: () => import("./views/cs/ToursList.vue")
        },
        {
          path: "tour/:id",
          name: "counter_strike_tour",
          component: () => import("./views/cs/Tour.vue")
        },
        {
          path: "rules",
          name: "counter_strike_rules",
          component: () => import("./views/cs/Rules.vue")
        }
      ]
    },
    // {
    //   path: "/mir-tankov",
    //   component: () => import("./views/Layout.vue"),
    //   children: [
    //     {
    //       path: "",
    //       name: "mir-tankov_home",
    //       component: () => import("./views/wot/Home.vue")
    //     },
    //     {
    //       path: "tours",
    //       name: "mir-tankov_tours",
    //       component: () => import("./views/wot/ToursList.vue")
    //     },
    //     {
    //       path: "tour/:id",
    //       name: "mir-tankov_tour",
    //       component: () => import("./views/wot/Tour.vue")
    //     },
    //     {
    //       path: "rules",
    //       name: "mir-tankov_rules",
    //       component: () => import("./views/wot/Rules.vue")
    //     }
    //   ]
    // },
    {
      path: "/info",
      name: "info",
      component: () => import("./views/Info.vue")
    },
    {
      path: "/404",
      name: "404",
      component: () => import("./views/NotFound.vue")
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]
});
